import { get, isEmpty } from 'lodash-es';

import { getArticlesWithoutSlug } from '~routes/news/js/news-utils';

import { getContentfulPageContentForSlug } from '~coreModules/contentful/js/contentful-client';
import { shapeContentfulContentPageResponse } from '~coreModules/contentful/js/contentful-utils';

import {
    CONTENT_TYPES,
    CONTENT_SLUGS,
    NEWS_HOMEPAGE_ARTICLE_PAGE_SIZE,
    NEWS_TRAY_PAGE_SIZE,
} from '~modules/contentful/js/contentful-constants';

import { SET_404_ERROR, SET_500_ERROR } from '~coreModules/core/js/store';

export const NEWS_MODULE_NAME = 'news';

export const FETCH_NEWS_HOMEPAGE_CONTENT = 'FETCH_NEWS_HOMEPAGE_CONTENT';
export const FETCH_RECENT_NEWS_ARTICLES = 'FETCH_RECENT_NEWS_ARTICLES';
export const FETCH_ARTICLE_DETAILS = 'FETCH_ARTICLE_DETAILS';
export const SET_NEWS_HOMEPAGE_CONTENT = 'SET_NEWS_HOMEPAGE_CONTENT';
export const SET_RECENT_NEWS_ARTICLES = 'SET_RECENT_NEWS_ARTICLES';
export const SET_ARTICLE_DETAILS = 'SET_ARTICLE_DETAILS';

export default {
    namespaced: true,

    state: () => ({
        newsHomeContent: null,
        allNewsArticles: [],
        articleDetails: {},
    }),

    /* eslint-disable no-param-reassign */
    mutations: {
        [SET_NEWS_HOMEPAGE_CONTENT](state, content) {
            state.newsHomeContent = {
                ...content,
                pressContent: shapeContentfulContentPageResponse(content.pressContent),
            };
        },
        [SET_RECENT_NEWS_ARTICLES](state, allArticles) {
            state.allNewsArticles = allArticles;
        },
        [SET_ARTICLE_DETAILS](state, articleDetails) {
            state.articleDetails = shapeContentfulContentPageResponse(articleDetails);
        },
    },

    /* eslint-enable no-param-reassign */
    actions: {
        async [FETCH_NEWS_HOMEPAGE_CONTENT]({ commit }) {
            try {
                const res = await getContentfulPageContentForSlug({
                    contentfulSvc: this.$contentfulSvc,
                    contentType: CONTENT_TYPES.NEWS_HOME,
                    slug: CONTENT_SLUGS.NEWS_HOME,
                    isTimed: false,
                });

                if (!isEmpty(res)) {
                    commit(SET_NEWS_HOMEPAGE_CONTENT, res);
                } else {
                    const error = `No content is authored for ${CONTENT_SLUGS.NEWS_HOME}`;
                    this.$logger.debugError(error);
                    commit(SET_404_ERROR, { message: error }, { root: true });
                }
            } catch (error) {
                const errorMsg = 'Failed to fetch news homepage content';
                this.$logger.debugError(`${errorMsg}, `, error);
                commit(SET_500_ERROR, errorMsg, { root: true });
            }
        },
        async [FETCH_RECENT_NEWS_ARTICLES]({ commit }, pageSize = NEWS_HOMEPAGE_ARTICLE_PAGE_SIZE) {
            try {
                const res = await getContentfulPageContentForSlug({
                    contentfulSvc: this.$contentfulSvc,
                    contentType: CONTENT_TYPES.NEWS_ARTICLE_SUMMARY,
                    // + 1 to account for omitting the featured article, or currently viewed article
                    limit: pageSize + 1,
                    queryParams: {
                        'fields.hidden': false,
                        order: '-fields.articleDate',
                    },
                });

                if (res) {
                    commit(SET_RECENT_NEWS_ARTICLES, res);
                } else {
                    this.$logger.debugError(`No content is authored for ${CONTENT_SLUGS.NEWS_HOME}`);
                }
            } catch (error) {
                this.$logger.debugError(`Failed to fetch homepage content for ${CONTENT_SLUGS.NEWS_HOME}: `, error);
            }
        },
        async [FETCH_ARTICLE_DETAILS]({ commit }, contentSlug) {
            try {
                const res = await getContentfulPageContentForSlug({
                    contentfulSvc: this.$contentfulSvc,
                    contentType: CONTENT_TYPES.NEWS_ARTICLE,
                    slug: contentSlug,
                });

                if (!isEmpty(res)) {
                    commit(SET_ARTICLE_DETAILS, res);
                } else {
                    const error = `No content is authored for ${CONTENT_TYPES.NEWS_ARTICLE} : ${contentSlug}`;

                    commit(SET_ARTICLE_DETAILS, {});
                    commit(SET_404_ERROR, { message: error }, { root: true });
                    this.$logger.debugError(error);
                }
            } catch (error) {
                // eslint-disable-next-line max-len
                const errorMsg = `Failed to fetch article details for ${CONTENT_TYPES.NEWS_ARTICLE} : ${contentSlug}`;

                commit(SET_ARTICLE_DETAILS, {});
                commit(SET_500_ERROR, errorMsg, { root: true });
                this.$logger.debugError(errorMsg, error);
            }
        },
    },

    getters: {
        homepageNewsArticles(state) {
            const { allNewsArticles } = state;
            const featuredArticleSlug = get(state, 'newsHomeContent.heroArticle.slug');

            return getArticlesWithoutSlug(allNewsArticles, featuredArticleSlug, NEWS_HOMEPAGE_ARTICLE_PAGE_SIZE);
        },
        moreArticlesForSlug(state) {
            const { allNewsArticles } = state;

            return slug => getArticlesWithoutSlug(allNewsArticles, slug, NEWS_TRAY_PAGE_SIZE);
        },
    },
};
