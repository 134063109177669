import { isEmpty, filter } from 'lodash-es';

export function getArticlesWithoutSlug(articles, omitSlug, limit) {
    if (!isEmpty(articles)) {
        return filter(articles, article => article.slug !== omitSlug)
            .slice(0, limit);
    }

    return [];
}

export default getArticlesWithoutSlug;
