<template>
    <BaseLink :to="articleDetailLink">
        <div
            :class="{
                'c-article-card': true,
                'c-article-card--featured': isFeatured
            }"
        >
            <div
                :class="{
                    'c-article-card__image-container': true,
                    'c-article-card__image-container--featured': isFeatured
                }"
            >
                <BaseImage
                    :defaultURL="articleImgUrl"
                    :imgClass="'c-article-card__img'"
                    :title="articleImgTitle"
                />
            </div>
            <div
                :class="{
                    'c-article-card__content-container': true,
                    'c-article-card__content-container--featured': isFeatured
                }"
            >
                <div class="u-text--gray o-text--label u-spacer--1pt5">
                    <span>{{ tag }}</span>
                    <span class="c-article-card__separator">|</span>
                    <ClientMounted>
                        <template #default="{ isClientMounted }">
                            <span v-if="isClientMounted">{{ articleDate }}</span>
                        </template>
                    </ClientMounted>
                </div>
                <h2 class="u-spacer--1">
                    {{ articleTitle }}
                </h2>
                <p class="u-spacer--2">
                    {{ articlePreview }}
                </p>
                <span class="o-text--link">
                    {{ ctaText }}
                </span>
            </div>
        </div>
    </BaseLink>
</template>

<script>
import { get, size } from 'lodash-es';

import { MAX_ARTICLE_PREVIEW_LENGTH } from '~routes/news/js/news-constants';

import { commonRouteNames } from '~modules/core/js/router-constants';

import BaseImage from '~coreModules/core/components/ui/BaseImage.vue';
import ClientMounted from '~coreModules/core/components/ui/ClientMounted.vue';

export default {
    name: 'ArticleCard',
    components: {
        BaseImage,
        ClientMounted,
    },
    props: {
        isFeatured: {
            type: Boolean,
            default: false,
        },
        articleSummary: {
            type: Object,
            required: true,
        },
    },
    computed: {
        articleDetailLink() {
            return {
                name: commonRouteNames.news.slug,
                params: { slug: get(this.articleSummary, 'slug') },
            };
        },
        articleTitle() {
            return get(this.articleSummary, 'articleTitle');
        },
        articlePreview() {
            const preview = get(this.articleSummary, 'articlePreview');

            return size(preview) > MAX_ARTICLE_PREVIEW_LENGTH ?
                `${preview.slice(0, MAX_ARTICLE_PREVIEW_LENGTH - 3)}...` : preview;
        },
        articleImgUrl() {
            return get(this.articleSummary, 'image.smallImage.url');
        },
        articleImgTitle() {
            return get(this.articleSummary, 'image.title');
        },
        articleDate() {
            const date = get(this.articleSummary, 'articleDate');
            return this.$d(new Date(Date.parse(date)), 'short');
        },
        ctaText() {
            return get(this.articleSummary, 'cta');
        },
        tag() {
            return get(this.articleSummary, 'tags.0');
        },
    },
};
</script>

<style lang="scss">
    .c-article-card {
        background-color: $nu-white;
        color: $nu-black;
        width: 100%;
        height: 100%;

        &--featured {
            @include breakpoint(medium) {
               display: flex;
            }
        }

        &__image-container {
            &--featured {
                @include breakpoint(medium) {
                    width: 60%;
                    margin: auto;
                }
            }
        }

        &__content-container {
            padding: $nu-spacer-3;

            @include breakpoint(medium) {
                padding: $nu-spacer-4;
            }

            &--featured {
                @include breakpoint(medium) {
                    width: 40%;
                    margin: auto;
                }
            }
        }

        &__separator {
            color: $nu-gray--medium;
            margin: 0 $nu-spacer-0pt5;
        }

        &__img {
            display: block;
            width: 100%;
        }
    }
</style>
